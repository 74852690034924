<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('measure.sq.overview')" class="catalog">
    <CCol cols="12" lg="12">
      <CCard class="h-100 mb-0">
        <CCardHeader>
          <CRow>
            <CCol cols="4" xl="4" class="pt-0 pb-0 text-left">
              {{$t('sq.satisquestions')}}
            </CCol>
            <CCol cols="8" xl="8" class="pt-0 pb-0 text-xl-right">
              <div class="mt-1 mt-xl-0 d-inline-block align-top">
                <b-switch class="mt-0 mb-0 mr-2"
                          v-model="showInactive"
                          size="is-small"
                          @input="filterCatalogData()">
                  {{ $t('common.show_inactive') }}
                </b-switch>
                <b-switch v-if="activeView === 'catalog'" class="mt-0 mb-0 mr-2 ml-0" v-model="itemFocus" size="is-small">{{ $t('measure.Focus_on_satisquestions') }}</b-switch>
                <CButton v-if="checkPermission('measure.sq.sqinsights')" class="d-inline-block" color="primary" @click="showCategoriesInsights()">
                  <i class="far fa-chart-bar mr-2"/>
                  <span>{{ $t('measure.Pillars_insights') }}</span>
                </CButton>
                <CButtonGroup>
                  <CButton color="outline-secondary" v-for="(value, key) in ['catalog', 'items']" :key="key" :pressed="value === activeView ? true : false" @click="activeView = value; getCatalogData();">
                    <i v-if="value === 'catalog'" class="fas fa-columns"></i>
                    <i v-if="value === 'items'" class="fas fa-list"></i>                    
                  </CButton>
                </CButtonGroup>               
              </div>
            </CCol> 
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CRow class="m-0">
            <CCol v-if="activeView === 'catalog' && !itemFocus" cols="4" lg="4" class="p-0">
              <div>
                <CRow class="m-0 actions">
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">
                    <span class="text-uppercase">
                      <b>{{$t('sq.Pillars')}}</b> 
                      <b-tag v-if="categories.length > 0" class="info ml-2">{{categories.length}}</b-tag>
                    </span>
                  </CCol>
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
                    <div v-if="checkPermission('measure.sq.addpillar')" class="mt-1 mt-xl-0 d-inline-block align-top">
                      <CButton class="m-0 link" color="primary" @click="openSidebarRight('sq_category_details', { sq_category_id_external:  null });">
                        <i class="fas fa-plus mr-1"/>
                        <span>{{$t('sq.Add_pillar')}}</span>
                      </CButton>
                    </div>
                  </CCol> 
                </CRow>
                <CRow>
                  <CCol cols="12" lg="12" v-bind:class="{'pb-0' : !categoriesLoading}">
                    <div v-if="categoriesLoading">
                      <loadingSpinner mode="auto" v-if="categoriesLoading" :content="$t('common.Loading')"/>                            
                    </div>
                    <div v-else>                    
                      <b-table ref="categoriesTable"
                              class="data_table add_bottom_left_radius"
                              :data="categories"
                              :striped="true"
                              :hoverable="true"
                              :mobile-cards="true"
                              :paginated="categoriesPaginated"
                              :per-page="categoriesPerPage"
                              :current-page.sync="categoriesPage"
                              :pagination-simple="isPaginationSimple"
                              :pagination-position="paginationPosition"
                              :default-sort-direction="defaultSortDirection"
                              :sort-icon="sortIcon"
                              :sort-icon-size="sortIconSize"
                              :row-class="(row, index) => row.sq_category_id_external === activeCategory.sq_category_id_external && 'active'">
                            
                            <template slot-scope="props">                      
                              <b-table-column field="category_name" :label="$t('sq.Pillar')" :searchable="searchEnabled">
                                <div class="d-flex align-items-center mb-1">                    
                                  <span class="pointer" @click="getCategorySubcategories(props.row.category_name, props.row.sq_category_id_external);">
                                    <b>{{props.row.category_name}}</b>
                                  </span>
                                </div>
                                <span class="d-block meta">
                                  <span v-if="showInactive">
                                    {{props.row.nr_of_subcategories}} {{props.row.nr_of_subcategories !== 1 ? $t('sq.subpillars') : $t('sq.subpillar')}} |
                                    {{props.row.nr_of_satisquestions}} {{props.row.nr_of_satisquestions !== 1 ? $t('sq.satisquestions') : $t('sq.satisquestion')}}
                                  </span>
                                  <span v-else>
                                    {{props.row.nr_of_active_subcategories}} {{props.row.nr_of_active_subcategories !== 1 ? $t('sq.subpillars') : $t('sq.subpillar')}} |
                                    {{props.row.nr_of_active_satisquestions}} {{props.row.nr_of_active_satisquestions !== 1 ? $t('sq.satisquestions') : $t('sq.satisquestion')}}
                                  </span>                                  
                                </span>
                                <div class="item_tags d-flex flex-row">
                                  <b-tag v-if="!props.row.is_active" class="mt-1 inactive">{{ $t('common.Inactive') }}</b-tag>
                                </div>
                              </b-table-column>                                                            
                              <b-table-column field="target_groups" label="" class="text-right" width="10%">
                                <targetGroupPopover v-if="props.row.target_groups.length > 1 || (props.row.target_groups[0] && props.row.target_groups[0].default_company_group === 'N')"
                                                    targetGroupsType="measure category"
                                                    :targetGroupsIdExternal="props.row.sq_category_id_external"
                                                    popoverPlacement="left"/>
                              </b-table-column>
                              <b-table-column field="action" width="10%">
                                <div class="d-flex justify-content-lg-end">
                                  <CDropdown v-if="checkPermission('measure.sq.pillarinsights') || checkPermission('measure.sq.editpillar')"
                                            color="primary"
                                            toggler-text=""
                                            :caret="false"
                                            class="ml-2 table_actions_dropdown">
                                    <div slot="toggler-content">
                                      <span class="d-flex align-items-center">
                                        {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                                      </span>
                                    </div>

                                    <CDropdownItem v-if="checkPermission('measure.sq.pillarinsights')">
                                      <div class="d-flex align-items-center" @click="showCategoryInsights( props.row.sq_category_id_external )">
                                        <div class="post_action_icon text-center mr-1">
                                          <i class="far fa-chart-bar"/>
                                        </div>
                                        <div class="flex-grow-1">
                                          <span>{{$t('common.Show_insights')}}</span>
                                        </div>
                                      </div>
                                    </CDropdownItem>
                                    <CDropdownItem v-if="checkPermission('measure.sq.editpillar')">
                                      <div class="d-flex align-items-center" 
                                           v-bind:class="{'disabled' : props.row.from_marketplace === 'Y'}"
                                           v-c-tooltip="props.row.from_marketplace === 'Y' ? {placement: 'left', content: $t('measure.Pillar_managed_by_owner')} : ''" 
                                           @click="props.row.from_marketplace === 'N' ? openSidebarRight('sq_category_details', { sq_category_id_external: props.row.sq_category_id_external }) : null">
                                        <div class="post_action_icon text-center mr-1">
                                          <i class="fas fa-pen"/>
                                        </div>
                                        <div class="flex-grow-1">
                                          <span>{{$t('sq.Edit_pillar')}}</span>
                                        </div>
                                      </div>
                                    </CDropdownItem>
                                  </CDropdown>
                                </div>
                              </b-table-column>
                            </template>
                            <template slot="empty">
                              <div class="text-center">
                                <span>{{$t('sq.no_pillars_found')}}</span>
                              </div>                
                            </template> 
                      </b-table>
                    </div>            
                  </CCol>
                </CRow>
              </div>
            </CCol>

            <CCol v-if="activeView === 'catalog' && !itemFocus" cols="4" lg="4" class="p-0">
              <div>
                <CRow class="m-0 actions">
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">                    
                    <span v-if="activeCategory.sq_category_id_external" v-line-clamp="1" class="text-uppercase">
                      <b>{{activeCategory.category_name}}</b>
                      <b-tag v-if="subcategories.length > 0" class="info ml-2">{{subcategories.length}}</b-tag>
                    </span>
                    <span v-else class="text-uppercase"><b>{{$t('sq.Subpillars')}}</b></span>
                  </CCol>
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
                    <div v-if="checkPermission('measure.sq.addsubpillar') && activeCategory.sq_category_id_external" class="mt-1 mt-xl-0 d-inline-block align-top">
                      <CButton class="m-0 link"
                               color="primary"
                               @click="openSidebarRight('sq_subcategory_details', { sq_category: activeCategory, sq_subcategory_id_external: null })">
                        <i class="fas fa-plus mr-1"/>
                        <span>{{$t('sq.Add_subpillar')}}</span>
                      </CButton>
                    </div>
                  </CCol> 
                </CRow>
                <CRow v-if="activeCategory.sq_category_id_external">
                  <CCol cols="12" lg="12" v-bind:class="{'pb-0' : !subcategoriesLoading}">
                    <div v-if="subcategoriesLoading">
                      <loadingSpinner mode="auto" v-if="subcategoriesLoading" :content="$t('common.Loading')"/>                            
                    </div>
                    <div v-else>                    
                      <b-table ref="subcategoriesTable"
                              class="data_table"
                              :data="subcategories"
                              :striped="true"
                              :hoverable="true"
                              :mobile-cards="true"
                              :paginated="subcategoriesPaginated"
                              :per-page="subcategoriesPerPage"
                              :current-page.sync="subcategoriesPage"
                              :pagination-simple="isPaginationSimple"
                              :pagination-position="paginationPosition"
                              :default-sort-direction="defaultSortDirection"
                              :sort-icon="sortIcon"
                              :sort-icon-size="sortIconSize"
                              :row-class="(row, index) => row.sq_subcategory_id_external === activeSubcategory.sq_subcategory_id_external && 'active'">
                            
                            <template slot-scope="props">                              
                              <b-table-column field="subcategory_name" :label="$t('sq.Subpillar')" :searchable="searchEnabled">                        
                                <div class="d-flex align-items-center mb-1">                             
                                  <span class="pointer" @click="getSubcategoryItems(props.row.category_name, props.row.subcategory_name, props.row.sq_subcategory_id_external);">
                                    <b>{{props.row.subcategory_name}}</b>
                                  </span>
                                </div>
                                <span class="d-block meta">
                                  <span v-if="showInactive">
                                    {{props.row.nr_of_satisquestions}} {{props.row.nr_of_satisquestions !== 1 ? $t('sq.satisquestions') : $t('sq.satisquestion')}}
                                  </span>
                                  <span v-else>
                                    {{props.row.nr_of_active_satisquestions}} {{props.row.nr_of_active_satisquestions !== 1 ? $t('sq.satisquestions') : $t('sq.satisquestion')}}
                                  </span>
                                </span>
                                <div class="item_tags d-flex flex-row">
                                  <b-tag v-if="!props.row.is_active" class="mt-1 inactive">{{ $t('common.Inactive') }}</b-tag>
                                </div>                                                              
                              </b-table-column>
                              <b-table-column field="action" width="10%">
                                <div class="d-flex justify-content-lg-end">
                                  <CDropdown v-if="checkPermission('measure.sq.subpillarinsights') || checkPermission('measure.sq.editsubpillar')"
                                             color="primary"
                                             toggler-text=""
                                             :caret="false"
                                             class="ml-2 table_actions_dropdown">
                                    <div slot="toggler-content">
                                      <span class="d-flex align-items-center">
                                        {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                                      </span>
                                    </div>

                                    <CDropdownItem v-if="checkPermission('measure.sq.subpillarinsights')">
                                      <div class="d-flex align-items-center" @click="showSubcategoryInsights( props.row.sq_subcategory_id_external )">
                                        <div class="post_action_icon text-center mr-1">
                                          <i class="far fa-chart-bar"/>
                                        </div>
                                        <div class="flex-grow-1">
                                          <span>{{$t('common.Show_insights')}}</span>
                                        </div>
                                      </div>
                                    </CDropdownItem>
                                    <CDropdownItem v-if="checkPermission('measure.sq.editsubpillar')">
                                      <div class="d-flex align-items-center" 
                                           v-bind:class="{'disabled' : props.row.from_marketplace === 'Y'}"
                                           v-c-tooltip="props.row.from_marketplace === 'Y' ? {placement: 'left', content: $t('measure.Subpillar_managed_by_owner')} : ''"
                                           @click="props.row.from_marketplace === 'N' ? openSidebarRight('sq_subcategory_details', { sq_category: props.row.category, sq_subcategory_id_external: props.row.sq_subcategory_id_external }) : null">
                                        <div class="post_action_icon text-center mr-1">
                                          <i class="fas fa-pen"/>
                                        </div>
                                        <div class="flex-grow-1">
                                          <span>{{$t('sq.Edit_subpillar')}}</span>
                                        </div>
                                      </div>
                                    </CDropdownItem>
                                  </CDropdown>
                                </div>
                              </b-table-column>
                            </template>
                            <template slot="empty">
                              <div class="text-center">
                                <span>{{$t('sq.no_subpillars_found')}}</span>
                              </div>                
                            </template> 
                      </b-table>
                    </div>          
                  </CCol>
                </CRow>
                <CRow v-else class="m-0">
                  <CCol cols="12" lg="12">
                    <span>{{$t('measure.Select_category_for_subcategories')}}</span>
                  </CCol>
                </CRow>          
              </div>
            </CCol>

            <CCol :cols="activeView === 'catalog' && !itemFocus ? 4 : 12" :lg="activeView === 'catalog' && !itemFocus ? 4 : 12" class="p-0">
              <div>
                <CRow class="m-0 actions">
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">
                    <span v-if="activeSubcategory.sq_subcategory_id_external" v-line-clamp="1" class="text-uppercase">
                      <b>{{activeSubcategory.subcategory_name}}</b>
                      <b-tag v-if="items.length > 0" class="info ml-2">{{items.length}}</b-tag>
                    </span>
                    <span v-else class="text-uppercase"><b>{{$t('sq.satisquestions')}}</b></span>
                  </CCol>
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
                    <div v-if="checkPermission('measure.sq.add') && activeSubcategory.sq_subcategory_id_external" class="mt-1 mt-xl-0 d-inline-block align-top">                      
                      <CButton class="m-0 link" 
                               color="primary"
                               @click="openSidebarRight('satisquestion_details', { sq_subcategory: activeView === 'catalog' ? activeSubcategory : null, sq_question_id_external: null })">
                        <i class="fas fa-plus mr-1"/>
                        <span>{{$t('sq.Add_satisquestion')}}</span>
                      </CButton>
                    </div>
                  </CCol> 
                </CRow>
                <CRow v-if="(activeView === 'catalog' && activeSubcategory.sq_subcategory_id_external) || activeView === 'items'">
                  <CCol cols="12" lg="12" v-bind:class="{'pb-0' : !itemsLoading}">
                    <div v-if="itemsLoading">
                      <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
                    </div>
                    <div v-else>
                      <b-table ref="itemsTable"
                              class="data_table add_bottom_right_radius"
                              :data="items"
                              :striped="true"
                              :hoverable="true"
                              :mobile-cards="true"
                              :paginated="itemsPaginated"
                              :per-page="itemsPerPage"
                              :current-page.sync="itemsPage"
                              :pagination-simple="isPaginationSimple"
                              :pagination-position="paginationPosition"
                              :default-sort-direction="defaultSortDirection"
                              :sort-icon="sortIcon"
                              :sort-icon-size="sortIconSize">
                            
                            <template slot-scope="props">
                              <b-table-column field="question" :label="$t('sq.satisquestion')" :searchable="searchEnabled">
                                <span>{{props.row.question}}</span>
                                <div class="item_tags d-flex flex-row">
                                  <b-tag v-if="!props.row.is_active" class="mt-1 inactive">{{ $t('common.Inactive') }}</b-tag>
                                </div>                                
                              </b-table-column>
                              <b-table-column field="subcategory.label" :visible="activeView === 'items' || itemFocus" :label="$t('sq.Subpillar')" :searchable="searchEnabled" width="25%">
                                <span>{{props.row.subcategory.label}}</span>
                              </b-table-column>
                              <b-table-column field="answer_options" :visible="activeView === 'items' || itemFocus" v-bind:label="$t('Emojis')" width="15%">
                                <span v-for="(option, index) in props.row.answer_options" v-bind:key="option.answer_name" v-html="getTwemoji(option.emoji)" v-bind:class="{'mr-1' : (index + 1) < props.row.answer_options.length}"></span>
                              </b-table-column>                              

                              <b-table-column field="actions" :visible="checkPermission('measure.sq.edit')">
                                <div class="d-flex justify-content-lg-end">
                                  <CButton color="primary"
                                           class="d-inline-block m-0"
                                           v-bind:class="{'disabled' : props.row.from_marketplace === 'Y'}"
                                           v-c-tooltip="props.row.from_marketplace === 'Y' ? {placement: 'left', content: $t('measure.SatisQuestions_managed_by_owner')} : ''"
                                           @click="props.row.from_marketplace === 'N' ? openSidebarRight('satisquestion_details', { sq_subcategory: activeView === 'catalog' ? props.row.subcategory : null, sq_question_id_external: props.row.sq_question_id_external }) : null">
                                    <i class="fas fa-pen"/>
                                  </CButton>
                                </div>
                              </b-table-column>
                            </template>
                            <template slot="empty">
                              <div class="text-center">                                                          
                                <span>{{$t('sq.no_satisquestions_found')}}</span>             
                              </div>                
                            </template> 
                      </b-table>
                    </div>
                  </CCol>
                </CRow>
                <CRow v-else class="m-0">
                  <CCol cols="12" lg="12">
                    <span>{{$t('measure.Select_subcategory_for_satisquestions')}}</span>
                  </CCol>
                </CRow>           
              </div>
            </CCol>        
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>       
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue'
import targetGroupPopover from '@/components/common/targetGroupPopover.vue'
import noPermission from '@/components/common/noPermission.vue';

export default {
  name: 'Library',
  components: {
    loadingSpinner,
    targetGroupPopover,
    noPermission
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      activeView: 'catalog',
      itemFocus: false,
      activeCategory: {
        category_name: null,
        sq_category_id_external: null        
      },
      activeSubcategory: {
        subcategory_name: null,
        sq_subcategory_id_external: null
      },
      categories: [],
      categoriesLoading: false,
      categoriesLoaded: false,
      categoriesPaginated: false,
      categoriesPerPage: 8,
      categoriesPage: 1,
      subcategories: [],
      subcategoriesLoading: false,
      subcategoriesPaginated: false,
      subcategoriesPerPage: 8,
      subcategoriesPage: 1,
      items: [],
      itemsLoading: false,
      itemsLoaded: false,
      itemsPaginated: false,
      itemsPerPage: 8,
      itemsPage: 1,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      searchEnabled: true,
      showInactive: false       
    }
  },
  methods: {
    getCatalogData() {
      // Clear the data
      this.categories = [];         
      this.subcategories = [];      
      this.items = [];
      // Update the active data
      this.activeCategory = { category_name: null, sq_category_id_external: null };
      this.activeSubcategory = { subcategory_name: null, sq_subcategory_id_external: null };
      // Get the categories of items based on the activeView
      if(this.activeView === 'catalog') {
        this.getCategories();        
        this.categoriesPage = 1;
        this.itemFocus = false;
      } else {
        this.getSatisQuestions();
        this.itemsPage = 1;
      }
    },
    filterCatalogData() {
      if(this.activeView === 'catalog') {
        this.getCategories();
        
        if(this.activeCategory.category_name && this.activeCategory.sq_category_id_external) {
          this.getCategorySubcategories(this.activeCategory.category_name, this.activeCategory.sq_category_id_external);
        }

        if(this.activeCategory.category_name && this.activeSubcategory.subcategory_name && this.activeSubcategory.sq_subcategory_id_external) {
          this.getSubcategoryItems(this.activeCategory.category_name, this.activeSubcategory.subcategory_name, this.activeSubcategory.sq_subcategory_id_external);
        }
      } else {
        this.getSatisQuestions();
      }
    },
    getSatisQuestions() {
      // Start the loader
      if(!this.itemsLoaded) this.itemsLoading = true;
      // Get the items
      axios.get(process.env.VUE_APP_API_URL + '/v1/measure/satisquestions')
      .then(res => {  
        this.items = res.data.data;
        // Filter on active status if necessary
        if(!this.showInactive) this.items = this.items.filter( i => ['Y'].includes( i.active ) );
        // Check if the pagination should be enabled
        (this.items.length > this.itemsPerPage) ? this.itemsPaginated = true : this.itemsPaginated = false;
        // Stop the loader
        this.itemsLoading = false;
        // Update the itemsLoaded value
        this.itemsLoaded = true;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getSatisQuestionDetails(itemIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/measure/satisquestion/' + itemIdExternal)
      .then(res => {
        let updatedItem = res.data.data;

        // Check if table refs are available
        if(this.$refs !== undefined && this.$refs.itemsTable !== undefined ) {
          let itemIndex = this.$refs.itemsTable.data.findIndex(x => x.sq_question_id_external == updatedItem.sq_question_id_external);

          this.$refs.itemsTable.data[itemIndex].question = updatedItem.question;
          this.$refs.itemsTable.data[itemIndex].subcategory = updatedItem.subcategory;       
        }    
      })
      .catch(err => {
        console.error(err); 
      });
    },  
    getCategories() {
      // Start the loader
      if(!this.categoriesLoaded) this.categoriesLoading = true;
      this.categoriesPaginated = false;
      // Get the categories
      axios.get(process.env.VUE_APP_API_URL + '/v1/measure/satisquestions/categories/overview')
      .then(res => {
        this.categories = res.data.data;
        // Filter on active status if necessary
        if(!this.showInactive) this.categories = this.categories.filter( i => ['Y'].includes( i.active ) );
        // Check if the pagination should be enabled
        (this.categories.length > this.categoriesPerPage) ? this.categoriesPaginated = true : this.categoriesPaginated = false;
        // Stop the loader
        this.categoriesLoading = false;
        // Update the categoriesLoaded value
        this.categoriesLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getCategorySubcategories(categoryName, categoryIdExternal) {
      // Check if a different category is selected
      if(this.activeCategory.sq_category_id_external !== categoryIdExternal) {
        // Start the loader
        this.subcategoriesLoading = true;      
        // Clear the subcategories (if necessary) and items            
        this.subcategories = [];
        this.items = [];
        // Update the active data
        this.activeCategory = { category_name: categoryName, sq_category_id_external: categoryIdExternal };
        this.activeSubcategory = { subcategory_name: null, sq_subcategory_id_external: null };
      }
      // Get the subcategories
      axios.get(process.env.VUE_APP_API_URL + '/v1/measure/satisquestions/category/' + categoryIdExternal + '/subcategories')
      .then(res => {
        this.subcategories = res.data.data;
        // Filter on active status if necessary
        if(!this.showInactive) this.subcategories = this.subcategories.filter( i => ['Y'].includes( i.active ) );        
        // Enable the pagination if necessary
        (this.subcategories.length > this.subcategoriesPerPage) ? this.subcategoriesPaginated = true : this.subcategoriesPaginated = false;        
        // Stop the loader
        this.subcategoriesLoading = false;
      })
      .catch(err => {
        console.error(err); 
      });      
    },
    getSubcategoryItems(categoryName, subcategoryName, subcategoryIdExternal) {
      // Check if a different subcategory is selected
      if(this.activeSubcategory.sq_subcategory_id_external !== subcategoryIdExternal) {
        // Start the loader
        this.itemsLoading = true;
        // Clear the items
        this.items = [];
        // Update the active data
        this.activeSubcategory = { category_name: categoryName, subcategory_name: subcategoryName, sq_subcategory_id_external: subcategoryIdExternal };
      }
      // Get the items
      axios.get(process.env.VUE_APP_API_URL + '/v1/measure/satisquestions/subcategory/' + subcategoryIdExternal + '/satisquestions')
      .then(res => {
        this.items = res.data.data;
        // Filter on active status if necessary
        if(!this.showInactive) this.items = this.items.filter( i => ['Y'].includes( i.active ) );        
        // Enable the pagination if necessary
        (this.items.length > this.itemsPerPage) ? this.itemsPaginated = true : this.itemsPaginated = false;
        // Stop the loader
        this.itemsLoading = false;
      })
      .catch(err => {
        console.error(err); 
      });      
    },
    showCategoriesInsights() {
      this.$router.push({path: '/insights/measure/categories'});
    },
    showCategoryInsights(id) {
      this.$router.push({path: `/insights/measure/category/${id.toString()}`});
    },
    showSubcategoryInsights(id) {
      this.$router.push({path: `/insights/measure/subcategory/${id.toString()}`});
    },
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }     
  },
  mounted: function() {
    this.getPlatformPermissions();
    this.getCategories();

    this.$bus.$on('update_sq_categories', () => {
      this.getCategories();
    }); 
    
    this.$bus.$on('update_sq_category_subcategories', (category) => {
      this.getCategorySubcategories(category.category_name, category.sq_category_id_external);
    });
    
    this.$bus.$on('update_sq_subcategory_items', (subcategory) => {
      this.getSubcategoryItems(subcategory.category_name, subcategory.subcategory_name, subcategory.sq_subcategory_id_external);
    });
    
    this.$bus.$on('update_satisquestion', (questionIdExternal) => {
      if(this.activeView === 'items') this.getSatisQuestionDetails(questionIdExternal);
    });    
  },
  beforeDestroy() {
    this.$bus.$off('update_sq_categories');
    this.$bus.$off('update_sq_category_subcategories');
    this.$bus.$off('update_sq_subcategory_items');
    this.$bus.$off('update_satisquestion');    
  }  
}
</script>